import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { dispatchWindowEvent } from 'helpers/eventDispatchHelper';
import trackEventWithBentoAnalytics from 'helpers/trackEventWithBentoAnalytics';
import { actionTypes, eventLabels, eventValues } from 'constants/tracking';
import { getAlert } from 'api';
import useFeatureFlags from 'hooks/useFeatureFlags';

const BaseAlertRoot = ({
  templateComponentMap,
  templateCallbackMap,
  alertType,
  eventType
}) => {
  const [alert, setAlert] = useState();
  const { has_diner_signup_ff } = useFeatureFlags();

  const loadAlert = async () => {
    const data = await getAlert({ alertType, has_diner_signup_ff });
    setAlert(data);
  };

  useEffect(() => {
    loadAlert();
  }, []);

  useEffect(() => {
    if (alert?.template) {
      templateCallbackMap[alert.template.toUpperCase()]();
      dispatchWindowEvent(eventType);
      trackEventWithBentoAnalytics(
        actionTypes.SHOW,
        `${eventLabels.ALERT_SHOWN}: ${eventValues.ALERT_TEMPLATE} - ${alert?.template}`
      );
    }
  }, [alert]);

  if (alert?.template) {
    const AlertContent = templateComponentMap[alert.template.toUpperCase()];
    return <AlertContent {...alert} />;
  }

  return null;
};

BaseAlertRoot.propTypes = {
  templateComponentMap: PropTypes.shape({
    SENSEI_OSAKA: PropTypes.func.isRequired,
    FUJI: PropTypes.func.isRequired
  }).isRequired,
  templateCallbackMap: PropTypes.shape({
    SENSEI_OSAKA: PropTypes.func.isRequired,
    FUJI: PropTypes.func.isRequired
  }).isRequired,
  alertType: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired
};

export default BaseAlertRoot;
