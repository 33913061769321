import {
  CADENCE_OPTIONS,
  ONE_WEEK_IN_DAYS,
  TWO_WEEKS_IN_DAYS,
  THIRTY_DAYS,
  LOCAL_STORAGE,
  SESSION_STORAGE,
  ALERTS_STORAGE_ITEM
} from 'constants/dinerSignupConstants';

export const getBulkAlertsStorageData = (storageType = LOCAL_STORAGE) => {
  const alerts = window[storageType].getItem(ALERTS_STORAGE_ITEM);

  if (!alerts) {
    return null;
  }
  let parsedAlertData;
  try {
    parsedAlertData = JSON.parse(alerts);
  } catch (error) {
    return null;
  }

  return parsedAlertData;
};

const getAlertStorageData = (alertId, storageType = LOCAL_STORAGE) => {
  const alertData = getBulkAlertsStorageData(storageType) || {};

  // if an alertId does not exist as a property of alertData object `alertData[alertId]`
  // then it would be undefined, however in this case we want to return null
  return alertData?.[alertId] || null;
};

const setAlertData = (alertId, storageType = LOCAL_STORAGE) => {
  const existingAlertsData = getBulkAlertsStorageData(storageType);
  let alertFormSubmitted = false;

  if (existingAlertsData?.[alertId]) {
    // don't overwrite whether the form was submitted already.
    alertFormSubmitted = existingAlertsData[alertId].alertFormSubmitted;
  }

  const alertObject = existingAlertsData || {};

  alertObject[alertId] = {
    alertFormSubmitted,
    lastShown: new Date().toISOString() // set the lastShown date to now in UTC
  };

  window[storageType].setItem(ALERTS_STORAGE_ITEM, JSON.stringify(alertObject));
};

const calculateDaysSinceAlertLastShown = alertLastShownInUTC => {
  const now = new Date();
  const nowAsUTCString = now.toISOString();
  return Math.floor(
    (new Date(nowAsUTCString) - new Date(alertLastShownInUTC)) / 86400000
  );
};

/*
Popups have a display cadence which determines how often we show them. 
For example:
 IN_ONE_WEEK means once we show the alert we shouldn't show it again until a week has passed.
 EVERY_TIME means always show it regardless of when the last time was. 
 NEVER means we only show it once and never again.
 ONCE_PER_SITE_VISIT means we only show the alert to the user once per the 
 entire session (using sessionStorage to store the alert)
 etc..
*/
export const shouldDisplayAlertBasedOnCadence = (alertId, displayCadence) => {
  let shouldDisplayAlert = true;

  if (displayCadence === CADENCE_OPTIONS.ONCE_PER_SITE_VISIT) {
    const existingSessionAlertData = getAlertStorageData(
      alertId,
      SESSION_STORAGE
    );

    if (!existingSessionAlertData) {
      setAlertData(alertId, SESSION_STORAGE);
    } else {
      shouldDisplayAlert = false;
    }

    return shouldDisplayAlert;
  }

  const alertDataFromStorage = getAlertStorageData(alertId);

  // Display the alert if:
  //  1. LocalStorage is empty for 'alert', meaning we've never shown it before
  //  2. Cadence is "every time", meaning we don't care when it was last shown
  if (!alertDataFromStorage || displayCadence === CADENCE_OPTIONS.EVERY_TIME) {
    setAlertData(alertId);
    return true;
  }

  const alertLastShownInUTC = alertDataFromStorage.lastShown;
  const daysSinceAlertLastShown =
    calculateDaysSinceAlertLastShown(alertLastShownInUTC);

  if (displayCadence === CADENCE_OPTIONS.NEVER && alertLastShownInUTC) {
    shouldDisplayAlert = false;
  }

  if (
    displayCadence === CADENCE_OPTIONS.IN_ONE_WEEK &&
    daysSinceAlertLastShown <= ONE_WEEK_IN_DAYS
  ) {
    shouldDisplayAlert = false;
  }

  if (
    displayCadence === CADENCE_OPTIONS.IN_TWO_WEEKS &&
    daysSinceAlertLastShown <= TWO_WEEKS_IN_DAYS
  ) {
    shouldDisplayAlert = false;
  }

  if (
    displayCadence === CADENCE_OPTIONS.IN_THIRTY_DAYS &&
    daysSinceAlertLastShown <= THIRTY_DAYS
  ) {
    shouldDisplayAlert = false;
  }

  // Update alert last shown time to now
  setAlertData(alertId);

  return shouldDisplayAlert;
};

// Don't show alert if the form was already submitted.
export const alertPreviouslySubmitted = alertId => {
  const alertData = getAlertStorageData(alertId);
  return !!alertData?.alertFormSubmitted;
};

// Focal point axis coords come from sushi as Floating points, we want them as percentages
// If we fail we return the 'center' keyword
export const focalPointFloatsToString = (focalPointX, focalPointY) => {
  let positionString = 'center center';
  if (
    !focalPointX ||
    !focalPointY ||
    typeof focalPointX !== 'number' ||
    typeof focalPointY !== 'number'
  ) {
    return positionString;
  }
  positionString = `${focalPointX * 100}% ${focalPointY * 100}%`;
  return positionString;
};
