import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';

import AlertButton from 'components/AlertButton';
import AlertForm from 'components/AlertForm';
import {
  SUBMIT_FORM,
  NO_THANKS,
  FORM_STATES
} from 'constants/dinerSignupConstants';
import { Close } from '@mui/icons-material';
import { focalPointFloatsToString } from 'utils/dinerSignup';

// TODO rename this component to something more generic since this is actually
//      not specific to promotions, this also handles announcements
const PromotionContent = ({
  context,
  template,
  alert_form_context,
  handleEmailChange,
  handleBirthdayChange,
  onClick,
  onClose,
  errors,
  formState
}) => (
  <Box
    sx={theme => ({
      borderRadius: theme.spacing(0.5),
      background: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: { xs: 'column', sm: 'row' },
      maxWidth: !context?.image?.image_url ? '550px' : '800px',
      [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: '600px'
      },
      maxHeight: { xs: '90vh' },
      minWidth: {
        xs: '100vw',
        sm: theme.spacing(53.75),
        md: theme.spacing(66.25)
      },
      overflow: 'scroll',
      '@media (orientation: landscape)': { overflow: 'initial' },
      '& *': {
        fontFamily: theme.brandStyles?.body?.fontFamily
      },
      ...theme.brandStyles?.modal
    })}
  >
    <Box
      sx={theme => ({
        display: {
          xs: 'flex',
          sm: 'none'
        },
        justifyContent: 'end',
        paddingX: theme.spacing(1.2),
        paddingY: theme.spacing(0.6)
      })}
    >
      <Close
        data-testid="small-close"
        onClick={() => {
          onClose();
        }}
        aria-hidden
        sx={theme => ({
          ...theme.brandStyles?.icon,
          cursor: 'pointer'
        })}
      />
    </Box>
    {/* Left Box */}
    {context?.image?.image_url && (
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: { xs: 'hidden' },
          width: {
            xs: '100%',
            sm: '50%'
          },
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
          }
        })}
      >
        <Box
          component="img"
          src={context?.image?.image_url}
          alt="image"
          sx={{
            height: '100%',
            width: '100%',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            objectFit: 'cover',
            objectPosition: focalPointFloatsToString(
              context?.image?.focal_point_x,
              context?.image?.focal_point_y
            )
          }}
        />
      </Box>
    )}
    {/* Right Box */}
    <Box
      sx={theme => ({
        // Don't add with or height to this element, or we'll end up with white borders around the image.
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'between',
        '@media (orientation: landscape)': { overflow: { xs: 'scroll' } },
        padding: {
          xs: theme.spacing(2),
          sm: `${theme.spacing(1.25)} ${theme.spacing(1.25)} ${theme.spacing(
            4
          )} ${theme.spacing(1.25)}`
        },
        width: {
          xs: '100%',
          sm: !context?.image?.image_url ? '100%' : '50%'
        }
      })}
    >
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'flex'
          },
          justifyContent: 'end'
        }}
      >
        <Close
          data-testid="medium-close"
          onClick={() => {
            onClose();
          }}
          aria-hidden
          sx={{
            cursor: 'pointer'
          }}
        />
      </Box>
      <Box
        sx={theme => ({
          flex: 1,
          display: 'flex',
          padding: 2,
          justifyContent: 'center',
          flexDirection: 'column',
          height: 'content-fit',
          ...theme.brandStyles?.modal
        })}
      >
        <Typography
          variant="h3"
          align="center"
          sx={theme => ({
            ...theme.brandStyles?.header
          })}
        >
          {context.header}
        </Typography>
        <Typography
          align="center"
          sx={theme => ({
            mb: 2,
            ...theme.brandStyles?.body
          })}
        >
          {context.body}
        </Typography>
        {context?.alertButton?.action === SUBMIT_FORM && (
          <AlertForm
            data-testid="alert-form"
            formContext={alert_form_context}
            handleEmailChange={handleEmailChange}
            handleBirthdayChange={handleBirthdayChange}
            errors={errors}
          />
        )}

        <AlertButton
          handleClick={onClick}
          type="submit"
          buttonProps={context.alertButton}
        />
        {template &&
          template.toLowerCase() === 'promotion' &&
          formState !== FORM_STATES.SUCCESS && (
            <Button
              variant="contained"
              sx={theme => ({
                marginBottom: theme.spacing(2),
                borderRadius: 0,
                ...theme.brandStyles?.button
              })}
              onClick={() => {
                onClose(NO_THANKS);
              }}
            >
              {NO_THANKS}
            </Button>
          )}
      </Box>
    </Box>
  </Box>
);

PromotionContent.propTypes = {
  context: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    alertButton: PropTypes.shape({
      action: PropTypes.string
    }),
    image: PropTypes.shape({
      id: PropTypes.number,
      image_url: PropTypes.string,
      focal_point_x: PropTypes.number,
      focal_point_y: PropTypes.number
    })
  }).isRequired,
  alert_form_context: PropTypes.shape({
    success_body: PropTypes.string,
    success_header: PropTypes.string,
    alert_buttons: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleBirthdayChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  template: PropTypes.string,
  formState: PropTypes.string
};

PromotionContent.defaultProps = {
  errors: [],
  template: '',
  formState: ''
};

export default PromotionContent;
