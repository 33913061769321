/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material';

import AlertModal from 'components/AlertModal';
import PromotionContent from 'components/PromotionContent';
import {
  CLOSE_POPUP,
  NONE,
  SUBMIT_FORM,
  FORM_STATES,
  CUSTOM,
  FUJI
} from 'constants/dinerSignupConstants';
import { getCSRFToken, getStyles } from 'api';
import FujiOverlayAlertContent from 'components/fuji/OverlayAlertContent';
import SenseiOsakaOverlayAlertContent from 'components/senseiOsaka/OverlayAlertContent';

import {
  alertPreviouslySubmitted,
  shouldDisplayAlertBasedOnCadence
} from 'utils/dinerSignup';
import { getSubmitFormPath } from 'api/helpers';
import trackEventWithBentoAnalytics from 'helpers/trackEventWithBentoAnalytics';
import { actionTypes, eventLabels } from 'constants/tracking';

const DinerSignupAlertContainer = ({
  id,
  alert_content_context,
  alert_form_context,
  display_cadence,
  template,
  legacy_template,
  preview,
  title
}) => {
  const isPreview = preview !== undefined;
  const [formState, setFormState] = useState(
    preview?.formState ?? FORM_STATES.INITIAL
  );

  useEffect(() => {
    if (isPreview) {
      setFormState(preview.formState);
    }
  }, [preview?.formState]);

  const contentContext = {
    header: alert_content_context.header,
    body: alert_content_context.body,
    alertButton: alert_content_context.alert_button,
    image: alert_content_context.image,
    images: alert_content_context.images,
    html_content: alert_content_context.html_content
  };
  const successContentContext = {
    header: alert_form_context?.success_header,
    body: alert_form_context?.success_body,
    alertButton: alert_form_context?.alert_buttons?.find(
      button => button.button_type === 'success'
    ),
    image: alert_content_context.image
  };
  const context =
    formState === FORM_STATES.INITIAL ? contentContext : successContentContext;

  /* eslint-disable no-unused-vars */
  const [isOpen, setIsOpen] = useState(true);
  const [email, setEmail] = useState();
  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [errors, setErrors] = useState();
  const [shouldDisplayAlert, setShouldDisplayAlert] = useState(false);
  const [alertsTheme, setAlertsTheme] = useState();

  const handleSubmitForm = async () => {
    const csrfToken = await getCSRFToken();

    const endpoint = getSubmitFormPath(id);
    const customerData = {
      email,
      birthday: `${month}/${day}`
    };
    if (!day || !month) {
      delete customerData.birthday;
    }

    try {
      const response = await axios.post(endpoint, customerData, {
        headers: { 'X-CSRFToken': csrfToken }
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  const handleButtonClick = async button => {
    if (!isPreview) {
      const inputValue = formState !== FORM_STATES.SUCCESS ? `: ${email}` : '';

      trackEventWithBentoAnalytics(
        actionTypes.CLICK,
        `${button.label}${inputValue}`
      );
    }

    if (button.action === SUBMIT_FORM) {
      // Don't submit the form if we are in the sushi preview.
      if (
        isPreview &&
        alert_form_context?.display_success &&
        formState !== FORM_STATES.SUCCESS
      ) {
        setFormState(FORM_STATES.SUCCESS);
        return;
      }

      const response = await handleSubmitForm();

      if (response.status === 400) {
        setErrors(response.data);
        return;
      }
      if (
        response.status === 200 &&
        alert_form_context?.display_success &&
        formState !== FORM_STATES.SUCCESS
      ) {
        setFormState(FORM_STATES.SUCCESS);
      } else {
        setIsOpen(false);
      }
    } else if (
      !!button.url &&
      (button.action !== CLOSE_POPUP || button.action !== NONE)
    ) {
      let buttonUrl = button.url;
      if (!button.url.startsWith('http')) {
        buttonUrl = `https://${button.url}`;
      }
      // See https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react
      const newWindow = window.open(buttonUrl, '_blank', 'noopener,noreferrer');
      setIsOpen(false);
      if (newWindow) {
        newWindow.opener = null;
      }
    } else {
      setIsOpen(false);
    }
  };
  const handleCloseModal = (label = null) => {
    if (!isPreview) {
      setIsOpen(false);

      trackEventWithBentoAnalytics(
        actionTypes.CLICK,
        `${eventLabels.ALERT_CLOSE_BUTTON}${label ? `: ${label}` : ''}`
      );
    }
  };

  const handleEmailChange = e => setEmail(e.target.value);

  const handleBirthdayChange = e => {
    setMonth(e.month);
    setDay(e.day);
  };

  useEffect(() => {
    const shouldAlertBeDisplayed =
      (!!alertsTheme || template === CUSTOM) &&
      !alertPreviouslySubmitted(id) &&
      shouldDisplayAlertBasedOnCadence(id, display_cadence);
    setShouldDisplayAlert(shouldAlertBeDisplayed);
  }, [id, display_cadence, alertsTheme]);

  useEffect(() => {
    const fetchStylesAndCreateTheme = async () => {
      const alertStyles = await getStyles(preview?.hostname);
      if (alertStyles) {
        setAlertsTheme(
          createTheme({
            typography: {
              fontFamily: alertStyles.body?.fontFamily,
              h3: {
                fontFamily: alertStyles.header?.fontFamily
              }
            },
            brandStyles: alertStyles
          })
        );
      }
    };
    // For CUSTOM template alerts, the styling comes from the website theme, not from the endpoint.
    if (template !== CUSTOM) {
      fetchStylesAndCreateTheme();
    }
  }, []);

  if (!shouldDisplayAlert) {
    return null;
  }

  if (template === CUSTOM) {
    // For CUSTOM template alerts, we display the existing OverlayAlertContent.
    // This will allow legacy alerts to continue displaying in the same way,
    // as well as new CUSTOM alerts to continue supporting custom css.
    const LegacyComponent =
      legacy_template.toLowerCase() === FUJI
        ? FujiOverlayAlertContent
        : SenseiOsakaOverlayAlertContent;
    return (
      <LegacyComponent
        content={contentContext.html_content}
        images={contentContext.images}
        title={title}
      />
    );
  }

  return (
    <ThemeProvider theme={alertsTheme}>
      <AlertModal
        open={isOpen}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: { xs: 'flex-end', sm: 'center' },
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <PromotionContent
          onClose={handleCloseModal}
          alert_form_context={alert_form_context}
          context={context}
          onClick={handleButtonClick}
          handleEmailChange={handleEmailChange}
          handleBirthdayChange={handleBirthdayChange}
          errors={errors}
          template={template}
          formState={formState}
        />
      </AlertModal>
    </ThemeProvider>
  );
};

DinerSignupAlertContainer.propTypes = {
  alert_content_context: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    image: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      image_url: PropTypes.string
    }),
    alert_button: PropTypes.shape({}),
    images: PropTypes.arrayOf(),
    html_content: PropTypes.string
  }),
  alert_form_context: PropTypes.shape({
    success_body: PropTypes.string,
    success_header: PropTypes.string,
    alert_buttons: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string,
        url: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        id: PropTypes.number.isRequired
      })
    ),
    display_success: PropTypes.bool
  }),
  display_cadence: PropTypes.string,
  template: PropTypes.string,
  legacy_template: PropTypes.string,
  title: PropTypes.string,
  // Ideally should remain in sync with sushi's src/alerts/contexts/previewStateContext.js
  preview: PropTypes.shape({
    formState: PropTypes.oneOf(Object.values(FORM_STATES)),
    hostname: PropTypes.string
  }),
  id: PropTypes.number.isRequired
};

DinerSignupAlertContainer.defaultProps = {
  alert_content_context: {},
  alert_form_context: {},
  display_cadence: '',
  template: '',
  legacy_template: '',
  title: '',
  preview: undefined
};

export default DinerSignupAlertContainer;
